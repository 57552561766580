.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

/**********************************
* Custom
**********************************/

.filter-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 0 20px 10px !important;
}
